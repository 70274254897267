import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jason/repros/jasoncraftscode-website/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
import Hero from "../../src/components/hero";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Home" description="" mdxType="Seo" />
    <Hero mdxType="Hero" />
    <h1 {...{
      "id": "index"
    }}>{`Index`}</h1>
    <p>{`Test index page`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      